export default {
    name: 'Founders',
    data: function () {
        return {
            activeFounder: null,
            founders: [{
                name: 'Simon Berglund',
                title: 'CTO',
                desc: "Ever since realizing that he could create the apps he missed himself he has spent way to much time designing and developing them. Created approximately 36 different versions of his portfolio.",
                linkedin: 'https://www.linkedin.com/in/berglnd/',
                email: 'simon@gokind.co',
                showInfo: false,
            }, {
                name: 'Hjalmar Norell',
                title: 'CINO',
                desc: 'Our innovation officer (CINO) is a rock when it comes to design thinking. With cognitive science as his background, he uses old social media tricks to transform lifeless sustainability reports into digestible data.',
                linkedin: 'https://www.linkedin.com/in/hjalmar-norell/',
                email: 'hjalmar@gokind.co',
                showInfo: false,

            }, {
                name: 'Gustaf Anselmsson',
                title: 'CEO',
                desc: 'After more than a decade of closing “deals” as a business lawyer he wanted to change direction. Seeing CSR being treated as just another internal tick in the box exercise was not good enough. With the add-on experience as digital transformation manager, and also part time e-commerce entrepreneur he put great trust in the power of digital communication.',
                linkedin: 'https://www.linkedin.com/in/gustafanselmsson/',
                email: 'gustaf@gokind.co',
                showInfo: false,
            }]
        }
    },
    methods: {
        showFounderInfo: function (founder) {
            if (window.innerWidth > 600) {
                this.activeFounder = founder;
                founder.showInfo = true
            }
        },
        hideFounderInfo: function (founder) {
            founder.showInfo = true
        }
    },
}