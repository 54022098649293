import LeadCard from '@/components/LeadCard/LeadCard.vue'
import Founders from '@/components/Founders/Founders.vue'
import FAQ from '@/components/FAQ/FAQ.vue'

export default {
    name: 'Mission',
    data: function () {
        return {}
    },
    props: {
        faqs: Object
    },
    components: {
        LeadCard,
        Founders,
        FAQ
    },
    methods: {
        getSeaIllu: function () {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return 'sea-bridge-mobile'
            } else if (window.innerWidth > 1500) {
                return 'sea-bridge-big'
            } else {
                return 'sea-bridge'
            }
        },
    },
    mounted: function () {
        window.scrollTo(0, 0)
    },
}