export default {
    name: 'LeadCard',
    props: {
        title: String,
        desc: String,
        img: String,
        bgColor: String,
        textColor: String,
        features: Array,
        link: {
            default: '/solution',
            type: String
        }
    }
}